import { device } from "@core-const";
import { TitleH1, TitleH4 } from "app-styled/title";
import styled, { css } from "styled-components";

interface SmylorMainTitleProps {
  SmylorInverse?: boolean;
  left?: boolean;
}
export const SmylorMainTitle = styled(TitleH1)<SmylorMainTitleProps>`
  text-align: center;
  font-weight: bold;

  ${({ theme, SmylorInverse, left }) => css`
    color: ${SmylorInverse && "black"};
    text-align: ${left && "left"};
    line-height: ${left && 1.2};
    @media ${device.laptop} {
      margin-bottom: ${theme["thm-space-xl"]};
    };

    @media ${device.tabletL} {
      font-size: 50px !important;
      /* word-break: break-all; */
    }
    @media ${device.mobileL} {
      font-size: 40px !important;
    }
  `}
`;

export const SmylorLeftTitle = styled(SmylorMainTitle)`
  text-align: left;
  font-size: 50px;
  /* word-break: break-all; */
  ${({ theme}) => css`
  @media ${device.laptop} {
      margin-bottom: ${theme["thm-space-lg"]};
    }
  `}
`;

export const SmylorMediumTitle = styled(TitleH4)`
  font-size: 30px;
  ${({ theme }) => css`
    color: ${theme["smylor-light-blue"]};
  `};
`;
